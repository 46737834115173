<template>
  <div class="app-customizer">
    <v-btn
      icon
      class="app-customizer-toggler rounded-0"
      :class="$vuetify.rtl ? 'rounded-r-lg' : 'rounded-l-lg'"
      color="white"
      large
      @click="isCustomizerOpen = !isCustomizerOpen"
    >
      <v-icon size="25">
        {{ icons.mdiFilterMenu }}
      </v-icon>
    </v-btn>
    <v-navigation-drawer
      v-model="isCustomizerOpen"
      :right="!$vuetify.rtl"
      temporary
      fixed
      width="400"
      class="app-customizer-drawer"
    >
      <div class="app-customizer-header customizer-section py-3">
        <span :style="`color: ${$vuetify.theme.themes.light.primary};font-size: 18px`">
          <b>{{ `${$t('menu.contratos')} / ${$t('menu.hotels')}` }} </b>
          <span
            class="ml-5"
            :style="`color: ${$vuetify.theme.themes.light.primary};text-decoration: underline;cursor: pointer;font-size: 14px;`"
            @click="clearFiltros"
          >{{ $t('lbl.clearFilters') }}</span>
        </span><br />
        <v-btn
          icon
          class="icon-customizer-close"
          @click="isCustomizerOpen = false"
        >
          <v-icon>
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-divider></v-divider>

      <perfect-scrollbar
        :options="perfectScrollbarOptions"
        class="ps-customizer"
      >
        <!--PROVEEDOR-->
        <div class="customizer-section">
          <span class="text--primary">{{ $t('lbl.proveedor') }}</span>
          <v-autocomplete
            v-if="!isLoadingProveedors"
            v-model="filters.proveedor_id"
            :items="itemsProveedor"
            :search-input.sync="searchProveedor"
            hide-details
            hide-selected
            :label="$t('lbl.proveedor')"
            outlined
            dense
            item-text="name_comercial"
            item-value="id"
            @change="refresh"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('lbl.proveedor') }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
              <span v-text="item.name_comercial"></span>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title>{{ item.name_comercial }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
          <v-skeleton-loader
            v-else
            class="mx-auto"
            type="card-heading"
          ></v-skeleton-loader>
        </div>
        <v-divider></v-divider>

        <!--IDENTIFICADOR-->
        <div class="customizer-section">
          <span class="text--primary">{{ $t('lbl.identificador') }}</span>
          <v-text-field
            v-if="!isLoadingProveedors"
            v-model="filters.identificador"
            :label="$t('lbl.identificador')"
            outlined
            dense
            single-line
            :append-icon="icons.mdiMagnify"
            @input="refresh()"
          >
          </v-text-field>
          <v-skeleton-loader
            v-else
            class="mx-auto"
            type="card-heading"
          ></v-skeleton-loader>
        </div>
        <v-divider></v-divider>

        <!--DESTINO-->
        <div class="customizer-section">
          <span class="text--primary">{{ $t('lbl.destino') }}</span>
          <v-autocomplete
            v-if="!isLoadingDestinos"
            v-model="filters.destino"
            :items="itemsDestino"
            :search-input.sync="searchDestino"
            hide-details
            hide-selected
            :label="$t('lbl.destino')"
            outlined
            dense
            @change="refresh"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('lbl.destino') }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
              <span v-text="item"></span>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title>{{ item }}</v-list-item-title>
                <v-list-item-subtitle></v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>
          <v-skeleton-loader
            v-else
            class="mx-auto"
            type="card-heading"
          ></v-skeleton-loader>
        </div>
        <v-divider></v-divider>

        <!--STATUS-->
        <div class="customizer-section">
          <span class="text--primary">{{ $t('lbl.state') }}</span>
          <v-select
            v-if="!isLoadingDestinos"
            v-model="filters.state"
            :items="states"
            :label="$t('lbl.state')"
            outlined
            dense
            hide-details
            item-text="name"
            item-value="value"
            @change="refresh"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('lbl.state') }}
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-select>
          <v-skeleton-loader
            v-else
            class="mx-auto"
            type="card-heading"
          ></v-skeleton-loader>
        </div>
        <v-divider></v-divider>

        <!--SOURCE-->
        <template v-if="user.nivel <= 0 && sources.length > 0">
          <div class="customizer-section">
            <span class="text--primary">{{ $t('dashboard.source') }}</span>
            <v-select
              v-model="filters.source"
              :items="sources"
              :label="$t('dashboard.source')"
              outlined
              dense
              hide-details
              item-text="name"
              item-value="slug"
              @change="refresh"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title>
                    {{ $t('dashboard.source') }}
                  </v-list-item-title>
                </v-list-item>
              </template>
            </v-select>
          </div>
          <v-divider></v-divider>
        </template>

        <!--CODES-->
        <div class="customizer-section">
          <span class="text--primary">Código</span>
          <v-autocomplete
            v-if="!isLoadingCodes"
            v-model="filters.code"
            :items="codesHotel"
            :search-input.sync="searchCodesHotel"
            hide-details
            hide-selected
            label="Código"
            outlined
            dense
            @change="refresh"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  Código
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
              <span v-text="item"></span>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title>{{ item }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
          <v-skeleton-loader
            v-else
            class="mx-auto"
            type="card-heading"
          ></v-skeleton-loader>
        </div>
        <v-divider></v-divider>
      </perfect-scrollbar>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { ref } from '@vue/composition-api'
// eslint-disable-next-line import/no-unresolved
import useAppConfig from '@core/@app-config/useAppConfig'

import {
  mdiFilterMenu, mdiClose, mdiStarOutline, mdiStar, mdiStarHalfFull, mdiMagnify,
} from '@mdi/js'

import { PerfectScrollbar } from 'vue2-perfect-scrollbar'

// 3rd Party

export default {
  components: {
    PerfectScrollbar,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    user: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    sources: {
      type: Array,
    },
  },
  setup() {
    const isCustomizerOpen = ref(false)
    // eslint-disable-next-line object-curly-newline
    const {
      isDark,
      isRtl,
      themes,
    } = useAppConfig()

    const perfectScrollbarOptions = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    return {
      isCustomizerOpen,

      isDark,
      isRtl,
      themes,

      // Perfect scrollbar options
      perfectScrollbarOptions,
    }
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      icons: {
        mdiFilterMenu,
        mdiClose,
        mdiStarOutline,
        mdiStar,
        mdiStarHalfFull,
        mdiMagnify,
      },
      permisos: localStorage.getItem('permisos_auth'),
      filters: {
        proveedor_id: null,
        identificador: null,
        destino: null,
        state: null,
        source: null,
        code: null,
      },
      isLoadingProveedors: true,
      proveedores: [],
      itemsProveedor: [],
      searchProveedor: null,
      isLoadingDestinos: true,
      destinos: [],
      itemsDestino: [],
      searchDestino: null,
      isLoadingCodes: true,
      codesHotel: [],
      allCodes: [],
      searchCodesHotel: null,
      states: [],
    }
  },
  computed: {
    ...mapState({
      // filters: state => state.app.filters,
    }),
  },
  watch: {
    searchProveedor(val) {
      if (val !== null && val.length > 0) {
        this.filterProveedor(val.toLowerCase())
      }
    },
    searchDestino(val) {
      if (val.length > 0) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterDestino(val.toLowerCase())
      } else {
        this.itemsDestino = []
      }
    },
    searchCodesHotel(val) {
      if (val) {
        // eslint-disable-next-line no-unused-expressions
        this.filterCodesHotel(val.toLowerCase())
      }
    },
  },
  created() {
    this.fetchDataFromEndpoints()

    this.states.push({
      name: this.$t('lbl.active'),
      value: 'active',
    })
    this.states.push({
      name: this.$t('lbl.stopSale'),
      value: 'stopSale',
    })
    this.states.push({
      name: this.$t('lbl.canceled'),
      value: 'canceled',
    })
    this.states.push({
      name: this.$t('lbl.vencido'),
      value: 'vencido',
    })
  },
  methods: {
    ...mapMutations([
      'filtrarCars',
      'filtersCotizadorHotelsResult',
      'clearCotizadorHotelsResultFilters',
      'clearFiltrosCars',
    ]),
    async fetchDataFromEndpoints() {
      try {
        const [
          resProvedores,
          resDestinos,
          resCodes,
        ] = await Promise.all([
          this.axios
            .post('affiliate/byproduct/hotels', { user: 1 }, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            }),
          this.axios
            .get('destinos-hotels-filter-product?field=country', {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            }),
          this.axios
            .post('contrate_hotels/list-season-codes', { user: 1 }, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            }),
        ])

        this.proveedores = resProvedores.data.data
        this.isLoadingProveedors = false

        this.destinos = resDestinos.data.data.data
        this.isLoadingDestinos = false

        this.allCodes = resCodes.data.data
        this.isLoadingCodes = false
      } catch (err) {
        console.error('Error fetching data:', err)
      }
    },

    filterProveedor(v) {
      this.itemsProveedor = []
      if (v === '') {
        this.itemsProveedor = []
      } else {
        this.itemsProveedor = this.proveedores.filter(e => e.name_comercial.toLowerCase())
      }
    },
    filterDestino(v) {
      this.itemsDestino = []
      if (v === '') {
        this.itemsDestino = []
      } else {
        this.itemsDestino = this.destinos.filter(e => e.toLowerCase())
      }
    },
    filterCodesHotel() {
      this.codesHotel = this.allCodes.filter(e => e.toLowerCase())
    },

    clearFiltros() {
      this.filters = {
        proveedor_id: null,
        identificador: null,
        destino: null,
        state: null,
        source: null,
        code: null,
      }
      this.$emit('refresh', { })
      this.isCustomizerOpen = false
    },
    refresh() {
      this.$emit('refresh', this.filters)
    },

    /*
    getProveedores() {
      this.axios
        .post('affiliate/byproduct/hotels', { user: 1 }, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.proveedores = res.data.data
        })
        .finally(() => {
          this.isLoadingProveedors = false
        })
    },
    getDestinos() {
      this.axios
        .get('destinos-hotels-filter-product?field=country', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.destinos = res.data.data.data
        })
        .finally(() => {
          this.isLoadingDestinos = false
        })
    },
    getCodesSeasons() {
      this.axios
        .post('contrate_hotels/list-season-codes', { user: 1 }, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.allCodes = res.data.data
        })
        .finally(() => {
          this.isLoadingCodes = false
        })
    },
    */
  },
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

.ps-customizer {
  height: calc(100% - 81px) !important;
}

.app-customizer-toggler {
  position: fixed;
  top: 50%;
  transform: translateX(-50%);
  background: var(--v-primary-base);
  @include ltr() {
    right: -22px;
  }
  @include rtl() {
    left: 20px;
  }
}

@include theme(app-customizer-drawer) using ($material) {
  background-color: map-deep-get($material, 'cards');
}

.app-customizer {
  z-index: 7;

  .v-label {
    font-size: 0.875rem;
  }

  .app-customizer-header {
    position: relative;
    .icon-customizer-close {
      position: absolute;
      @include ltr() {
        right: 20px;
      }
      @include rtl() {
        left: 20px;
      }
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .customizer-section {
    padding: 24px;
  }

  // Fixes Overlay is shown below SystemBar
  @at-root {
    .v-overlay {
      & + .v-application--wrap > .v-system-bar {
        z-index: 6 !important;
      }
    }
  }
}
</style>
